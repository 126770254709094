.App {
  text-align: center;
}

.App-logo {
  width: 60%;
  height: auto
}

.App-header {
  background-color: #112469;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    font-size: calc(10px + 2vmin);
}

.InstaLink {
  padding-top: 100px;
  position: absolute;
  bottom: 5%;
  color: white;
}

.instaLogo {
  width: 200px;
  height: auto;
}